(function() {
    'use strict';
    angular.module('eventvods')
        .controller('TeamController', ['GamesExtras', '$rootScope', '$routeParams', '$http', 'API_BASE_URL', '$location', '$anchorScroll', '$timeout', '$window',
            function(GamesExtras, $rootScope, $routeParams, $http, API, $location, $anchorScroll, $timeout, $window) {
				$window.prerenderReady = false;
				var gameOrders = {
					"lol": 1,
					"dota": 2,
					"csgo": 3,
					"overwatch": 4
				}
                var vm = this;
                vm.data = {};
                vm.limit = 20;
				vm.active = null;
				vm.added = [];

				if(typeof $routeParams.game === "string"){
					$rootScope.oldGame = $rootScope.game;
					$rootScope.game = $routeParams.game;
				}
				vm.displayParamCheck = function($index){
					return ($index % 8 == 0) && !($index >= (8*6));
				}
                vm.show = function($index) {
					vm.active = $index;
					$rootScope.game = vm.data[$index].game.slug;
					$location.path('/team/'+vm.data[$index].slug+'/'+vm.data[$index].game.slug, false).search({});
                    document.title = $rootScope.meta.title = vm.data[vm.active].name + " - " +  vm.data[$index].game.name + " - Eventvods - Esports on Demand";
                    $rootScope.meta.description = "Follow " + vm.data[$index].name + " matches across all the popular esports with Eventvods.";
					$anchorScroll("top");
                }
                vm.parseLink = function(match, link) {
                    if (match.placeholder) return "";
                    else return link;
                }
                vm.hasExtras = function(module, game) {
                    if (module.columns.length !== game.links.length) return false;
                    for (var i = 0; i < module.columns.length; i++) {
                        if (game.links[i].trim() !== "") return true;
                    }
                    return false;
                }
                vm.setHover = function(match, index, isHovered) {
                    if (index == 1)
                        match.team1H = isHovered;
                    else
                        match.team2H = isHovered;
                }
				vm.loading = true;
				$http.get(API + '/teams/slug/' + $routeParams.slug)
					.then(function(res) {
						vm.data = res.data;
						vm.data.sort(function(a,b){
							return gameOrders[a.game.slug] - gameOrders[b.game.slug];
						});
						if (vm.active == null){
							if(typeof $routeParams.game === "string"  && vm.data.length > 1) {
								for (var i = 0; i < vm.data.length; i++) {
									if (vm.data[i].game.slug === $routeParams.game) {
										vm.active = i;
										break;
									}
								}
							}
							else vm.active = 0;
						}
						$rootScope.game = vm.data[vm.active].game.slug;
						vm.data.stats = GamesExtras[vm.data[vm.active].game.slug].stats;
						vm.data.highlights = GamesExtras[vm.data[vm.active].game.slug].highlights;
						document.title = $rootScope.meta.title = vm.data[vm.active].name + " - " +  vm.data[vm.active].game.name + " - Eventvods";
						$rootScope.meta.description = "Follow " + vm.data[vm.active].name + " matches across all the popular esports with Eventvods.";
						vm.loading = false;
						$timeout(function(){
							$('[data-toggle="tooltip"]').tooltip();
							$window.prerenderReady = true;
						}, 1);
					},
					function(){
						$rootScope.meta.status = 404;
					});
            }
        ]);
}());
